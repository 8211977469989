import React, { Component, Fragment } from "react";
import {
  SENDER_NAME,
  SERVICETYPE,
  CAR_BRAND,
  STATUS,
  _TS,
  PROVIDER_NAME
} from "../../Helpers/Constants";
import { statusString, formatDate } from "../../Helpers/Helper";
import { StatusValue, ServiceTypeValue } from "./Categories/Extras";

import RequestDetails from "./RequestDetails";

class SummaryTable extends Component {
  constructor() {
    super();
  }

  onClick(request) {
    console.log(request);
  }

  render() {
    const { requests } = this.props;
    console.log(this.props.requests);
    return (
      <div className="  col-md-12 col-lg-12 col-xl-11 col-sm-12 justify-content-center">
        <table className="table table-light table-bordered table-hover text-center">
          <thead>
            <tr className="text-muted">
              <th>#</th>
              <th>Client</th>
              <th>Service</th>
              <th>Car Brand</th>
              <th>Status</th>
              <th>Date</th>
              <th>Provider</th>
            </tr>
          </thead>
          <tbody>
            {requests.map(request => {
              return (
                <tr
                  className="accordion-toggle"
                  data-toggle="collapse"
                  data-target={"#a" + request.id}
                  key={request.id}
                >
                  <th>{requests.indexOf(request) + 1}</th>
                  <td>{request[SENDER_NAME]}</td>
                  <td>
                    <ServiceTypeValue
                      type={request[SERVICETYPE]}
                    ></ServiceTypeValue>
                  </td>
                  <td>{request[CAR_BRAND]}</td>
                  <td>
                    <StatusValue status={request[STATUS]} />
                  </td>
                  <td>{formatDate(request[_TS])}</td>
                  <td>
                    <span className="badge badge-success width-75 py-2">
                      {request[PROVIDER_NAME]}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}

export default SummaryTable;
