export class AnalyticData {
  sales = "N 0.00";
  requests = 0;
  clients = 0;
  completed = 0;
  autoelectrician = {
    key: "Auto Electrician",
    requests: 0,
    revenue: "N 0.00"
  };
  automechanic = { key: "Auto Mechanic", requests: 0, revenue: "N 0.00" };
  autobody = { key: "Auto Body", requests: 0, revenue: "N 0.00" };
  towing = { key: "Towing", requests: 0, revenue: "N 0.00" };

  constructor(snapshot) {
    let sale = 0;
    if (snapshot && typeof snapshot.autobody === "number") {
      this.autobody.requests = snapshot.autobody;

      if (snapshot.revenue && typeof snapshot.revenue.autobody === "number") {
        sale = sale + snapshot.revenue.autobody;
        this.autobody.revenue = `N ${snapshot.revenue.autobody}`;
      }
    }
    if (snapshot && typeof snapshot.automechanic === "number") {
      this.automechanic.requests = snapshot.automechanic;
      if (
        snapshot.revenue &&
        typeof snapshot.revenue.automechanic === "number"
      ) {
        sale = sale + snapshot.revenue.automechanic;
        this.automechanic.revenue = `N ${snapshot.revenue.automechanic}`;
      }
    }
    if (snapshot && typeof snapshot.autoelectrician === "number") {
      this.autoelectrician.requests = snapshot.autoelectrician;
      if (
        snapshot.revenue &&
        typeof snapshot.revenue.autoelectrician === "number"
      ) {
        sale = sale + snapshot.revenue.autoelectrician;
        this.autoelectrician.revenue = `N ${snapshot.revenue.autoelectrician}`;
      }
    }
    if (snapshot && typeof snapshot.towing === "number") {
      this.towing.requests = snapshot.towing;
      if (snapshot.revenue && typeof snapshot.revenue.towing === "number") {
        sale = sale + snapshot.revenue.towing;
        this.towing.revenue = `N ${snapshot.revenue.towing}`;
      }
    }

    if (snapshot && typeof snapshot.total === "number") {
      this.requests = snapshot.total;
    }

    if (snapshot && typeof snapshot.clients === "object") {
      this.clients = snapshot.clients.length;
    }

    if (snapshot && typeof snapshot.completed === "number") {
      this.completed = snapshot.completed;
    }
    this.sales = `N ${sale}`;
  }

  summaryData = () => {
    return {
      sales: this.sales,
      requests: this.requests,
      clients: this.clients,
      completed: this.completed
    };
  };

  requestStats = () => {
    return [
      this.autobody,
      this.autoelectrician,
      this.automechanic,
      this.towing
    ];
  };
}
