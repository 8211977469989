import {
  AUTH_DISPATCH,
  REF_SERVICE_PROVIDERS,
  GET_ERRORS,
  REF_USERS,
  LOGOUT_DISPATCH
} from "../Helpers/Constants";
import * as firebase from "firebase";

export const loginAdmin = userData => dispatch => {
  const { email, password } = userData;
  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then(x => {
      firebase
        .firestore()
        .collection(REF_SERVICE_PROVIDERS)
        .doc(x.user.uid)
        .get()
        .then(snap => {
          if (snap.exists) {
            let data = snap.data();
            data.uid = x.user.uid;
            dispatch({
              type: AUTH_DISPATCH,
              payload: data
            });
          } else {
            dispatch({
              type: GET_ERRORS,
              payload: { email: "Invalid login credentials" }
            });
          }
        });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: { email: "Invalid login credentials" }
      });
    });
};

export const logout = () => dispatch => {
  firebase
    .auth()
    .signOut()
    .then(x => {
      dispatch({
        type: LOGOUT_DISPATCH,
        payload: {}
      });
    })
    .catch(err => {
      console.log(err);
      dispatch({
        type: LOGOUT_DISPATCH,
        payload: {}
      });
    });
};

/***
 * try {
    const result = await firebase
      .auth()
      .signInWithEmailAndPassword(email, password);
    const snap = await firebase
      .firestore()
      .collection(REF_SERVICE_PROVIDERS)
      .doc(result.user.uid)
      .get();
    if (snap.exists) {
      return Promise.resolve(snap.data());
    } else {
      throw new Error({ errors: { email: "Unauthorized Access" } });
    }
  } catch (err) {
    return Promise.reject({ errors: err });
  }
 */
