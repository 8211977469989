import { createStore, combineReducers, compose, applyMiddleware } from "redux";
import rootReducers from "./reducers/index";
import { firebaseConfig } from "./config/firconfig";
import * as firebase from "firebase";
import thunk from "redux-thunk";
//Reducers

const middleware = [thunk];

const initialState = {};

const store = createStore(
  rootReducers,
  initialState,
  compose(
    applyMiddleware(...middleware)
    //window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);

export default store;

//const config =
// //react- redux firebase config
// const rrfConfig = {
//   userProfiles: "users",
//   usesFirestoreForProfiles: true
// };

firebase.initializeApp(firebaseConfig);

//Init firestore

//const firestore = firebase.firestore();

// Add reduxFirestore store enhancer to store creator

// const createFirestoreWithFirebase = compose(
//   reactReduxFirebase(firebase, rrfConfig),
//   reduxFirestore(firebase)
// )(createStore);

// const rootReducers = combineReducers({
//   firebase: firebaseReducer,
//   firestore: firestoreReducer
// });

/*createFirestoreWithFirebase(
  rootReducers,
  initialState,
  compose(
    reactReduxFirebase(firebase),
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  )
);*/
