import React, { Component } from "react";
import { Link } from "react-router-dom";
import userImg from "../../img/user-circle-solid.svg";
import { user } from "../../Tests/TestData";
import Spinner from "../layout/Spinner";
import moment from "moment";

export default class ServiceProviderDetail extends Component {
  constructor() {
    super();
    this.state = {
      user: null
    };
  }

  componentDidMount() {
    this.setState({ user: user });
  }

  render() {
    const { user } = this.state;
    if (user == null) return <Spinner></Spinner>;
    const {
      name,
      serviceType,
      supportedVehicles,
      primaryPhone,
      secondaryPhone,
      workshopLocation,
      currentState,
      onSpotRepairs
    } = this.state.user;
    const { toggled } = this.state;
    return (
      <div class="sidebar sidebar-style-2" data-background-color="dark2">
        <div class="sidebar-wrapper scrollbar scrollbar-inner">
          <div class="sidebar-content">
            <div class="user">
              <div class="avatar-sm float-left mr-2">
                <img src={userImg}></img>
              </div>
              <div class="info">
                <a
                  data-toggle="collapse"
                  href="#collapseExample"
                  aria-expanded="true"
                >
                  <span>
                    {name}
                    <span class="user-level">Administrator</span>
                    <span class="caret"></span>
                  </span>
                </a>
                <div class="clearfix"></div>
              </div>
            </div>
            <ul class="nav nav-primary">
              <li class="nav-item active">
                <Link
                  class="justify-content-center collapsed ml-2"
                  aria-expanded="false"
                >
                  <p className="">{moment().format("ddd Do MMMM, YYYY")}</p>
                </Link>
              </li>
              <li class="nav-section">
                <span class="sidebar-mini-icon">
                  <i class="fa fa-ellipsis-h"></i>
                </span>
                <h4 class="text-section">Service Info</h4>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#base">
                  <i class="fas fa-layer-group"></i>
                  <p>Services Offerred</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="base">
                  <ul class="nav nav-collapse">
                    {serviceType.map(x => {
                      return (
                        <li key={x}>
                          <a href="components/avatars.html">
                            <span class="sub-item">{x}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#sidebarLayouts">
                  <i class="fas fa-car"></i>
                  <p>Supported Vehicles</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="sidebarLayouts">
                  <ul class="nav nav-collapse">
                    {supportedVehicles.map(x => {
                      return (
                        <li key={x}>
                          <a href="overlay-sidebar.html">
                            <span class="sub-item">{x}</span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#forms">
                  <i class="fas fa-address-card"></i>
                  <p>Contact Info</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="forms">
                  <ul class="nav nav-collapse">
                    <li>
                      <a href="forms/forms.html">
                        <span class="sub-item text-dark">{primaryPhone}</span>
                      </a>
                    </li>
                    <li>
                      <a href="forms/forms.html">
                        <span class="sub-item text-dark">{secondaryPhone}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#tables">
                  <i class="fas fa-map-marker-alt"></i>
                  <p>Workshop Location</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="tables">
                  <ul class="nav nav-collapse">
                    <li>
                      <a href="tables/tables.html">
                        <span class="sub-item">{workshopLocation}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#maps">
                  <i class="fas fa-flag"></i>
                  <p>Current State</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="maps">
                  <ul class="nav nav-collapse">
                    <li>
                      <a>
                        <span class="sub-item">{currentState}</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
              <li class="nav-item">
                <a data-toggle="collapse" href="#charts">
                  <i class="fas fa-wrench"></i>
                  <p>Onspot Repairs</p>
                  <span class="caret"></span>
                </a>
                <div class="collapse" id="charts">
                  <ul class="nav nav-collapse">
                    <li>
                      <a href="charts/charts.html">
                        <span class="sub-item">
                          {onSpotRepairs ? (
                            <p className="text-dark">
                              You offer OnSpot Repairs
                            </p>
                          ) : (
                            <p>You do not offer Onspot Repairs</p>
                          )}
                        </span>
                      </a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  }

  /*

  {moment().format("ddd Do MMMM, YYYY")}

  {onSpotRepairs ? (
                  <p className="text-dark">You offer OnSpot Repairs</p>
                ) : (
                  <p>You do not offer Onspot Repairs</p>
                )}
  render() {
    const { user } = this.state;
    return (
      <div>
        <div className="container-main">
          <div className="row justify-content-center">
            <img src={userImg} className="userimg"></img>
            <h5 className="display-5 text-light mt-1">Jeremy Bullock</h5>
            <hr style={{ border: "0.5px solid white", width: "250px" }}></hr>
          </div>

          <div className="row">
            <h6 className="text-light ml-4">Address</h6>
            <p className="text-light">Amasamn Road Near Adja Ajanton Street</p>
            <hr style={{ border: "0.5px solid white", width: "200px" }}></hr>
          </div>
          <div className="row">
            
          </div>
        </div>
      </div>
    );
  }
  */
}
