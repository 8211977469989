import React from "react";
import { statusString } from "../../../Helpers/Helper";

export const StatusValue = status => {
  const stausStr = statusString(status.status);

  switch (status.status) {
    case 1:
      return (
        <span className="badge badge-danger width-75 py-2">{stausStr}</span>
      );
    case 2:
      return (
        <span className="badge badge-primary width-75 py-2">{stausStr}</span>
      );
    case 3:
      return (
        <span className="badge badge-success width-75 py-2">{stausStr}</span>
      );
    default:
      return (
        <span className="badge badge-success width-75 py-2">{stausStr}</span>
      );
  }
};

export const ServiceTypeValue = type => {
  const ntype = type.type;

  switch (ntype) {
    case "AUTOELECTRICIAN":
      return (
        <span className="badge badge-info width-75 py-2">ELECTRICIAN</span>
      );
    case "AUTOBODY MECH":
      return (
        <span className="badge badge-secondary width-75 py-2">BODY MECH</span>
      );
    case "GENERAL MECH":
      return <span className="badge badge-dark width-75 py-2">AUTO MECH</span>;
    default:
      return <span className="badge badge-light width-75 py-2">TOWING</span>;
  }
};
