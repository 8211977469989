import React, { Component } from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { connect } from "react-redux";
import { logout } from "../../actions/authAction";
import PropTypes from "prop-types";

class AppNavBar extends Component {
  state = {
    auth: {
      isAuthenticated: false,
      user: {}
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth) {
      this.setState({ auth: nextProps.auth });
    }
  }

  onLogoutClick = e => {
    e.preventDefault();

    const { logout } = this.props;
    logout();
    // setCategories([]);
    // setRegions([]);
    // setUid(null);
    // setAccess(0);
    // setReload(true);
    //console.log("The settings ois, :",this.props);

    //this.setState({ username: "" });

    //window.location.reload();
  };

  render() {
    const { isAuthenticated, user } = this.state.auth;

    //const { allowRegistration} = this.props.settings;
    return (
      <nav
        className="navbar navbar-expand-md navbar-dark"
        style={{ backgroundColor: "#cb2027" }}
      >
        <div className="container">
          <Link to="/" className="navbar-brand">
            <h3 className="display-6">iAutomech</h3>
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarMain"
          >
            <span className="navbar-toggler-icon" navbar-toggler-icon="true" />
          </button>

          <div className="collapse navbar-collapse" id="navbarMain">
            <ul className="navbar-nav mr-auto">
              {isAuthenticated ? (
                <li>
                  <Link to="/dashboard" className="nav-link">
                    Dashboard
                  </Link>
                </li>
              ) : null}
            </ul>
            {isAuthenticated ? (
              <ul className="navbar-nav ml-auto">
                <li>
                  <Link to="/analytics" className="nav-link">
                    Analytics
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="/#"
                    className="nav-link"
                    onClick={this.onLogoutClick}
                  >
                    Logout
                  </a>{" "}
                </li>
                <li>
                  <h5 className="display-6 pt-2 ml-auto text-white">
                    {user.name}
                  </h5>
                </li>
              </ul>
            ) : (
              <ul className="navbar-nav ml-auto">
                <li>
                  <Link className="nav-link text-white" to="/login">
                    Admin Login
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </nav>
    );
  }
}

AppNavBar.propTypes = {
  firebase: PropTypes.object.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { logout }
)(AppNavBar); //compose(
//   firebaseConnect(),
//   withFirestore,
//   connect((state, props) => ({
//     auth: state.firebase.auth
//   }))
// )(AppNavBar);
