import * as firebase from "firebase";
import {
  REF_IAREQUESTS,
  PROVIDER_ID,
  GET_REQUEST,
  _TS
} from "../Helpers/Constants";
const QUERY_LIMIT = 20;

export const getRequestFor = (id, last) => dispatch => {
  let query = null;
  if (last) {
    query = firebase
      .firestore()
      .collection(REF_IAREQUESTS)
      .where(PROVIDER_ID, "==", id)
      .orderBy(_TS, "desc")
      .startAfter(last)
      .limit(QUERY_LIMIT);
  } else {
    query = firebase
      .firestore()
      .collection(REF_IAREQUESTS)
      .where(PROVIDER_ID, "==", id)
      .orderBy(_TS, "desc")
      .limit(QUERY_LIMIT);
  }

  query
    .get()
    .then(requests => {
      const alldocs = [];
      let last = null;
      if (requests.docs.length == QUERY_LIMIT) {
        last = requests.docs[QUERY_LIMIT - 1];
      }
      requests.docs.forEach(x => {
        const data = x.data();
        data.id = x.id;
        alldocs.push(data);
      });
      //console.log(`All Docs are: ${alldocs}`);
      dispatch({
        type: GET_REQUEST,
        payload: { docs: alldocs, last }
      });
    })
    .catch(err => {
      console.log("Error occurred here: " + err);
    });
};

export const requestsForSuper = last => dispatch => {
  let query = null;

  if (last) {
    query = firebase
      .firestore()
      .collection(REF_IAREQUESTS)
      .orderBy(_TS, "desc")
      .startAfter(last)
      .limit(QUERY_LIMIT);
  } else {
    query = firebase
      .firestore()
      .collection(REF_IAREQUESTS)
      .orderBy(_TS, "desc")
      .limit(QUERY_LIMIT);
  }

  query
    .get()
    .then(requests => {
      const alldocs = [];
      let last = null;
      if (requests.docs.length == QUERY_LIMIT) {
        last = requests.docs[QUERY_LIMIT - 1];
      }
      requests.docs.forEach(x => {
        const data = x.data();
        data.id = x.id;
        alldocs.push(data);
      });
      //console.log(`All Docs are: ${alldocs}`);
      dispatch({
        type: GET_REQUEST,
        payload: { docs: alldocs, last }
      });
    })
    .catch(err => {
      console.log("Error occurred here: " + err);
    });
};
