import { GET_ANALYTICS, GET_ERRORS } from "../Helpers/Constants";

const initialState = {
  data: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ANALYTICS:
      return { ...state, data: action.payload };
    default:
      return initialState;
  }
}
