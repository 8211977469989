import { GET_REQUEST } from "../Helpers/Constants";

const initialState = {
  requests: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_REQUEST:
      return {
        ...state,
        requests: action.payload
      };
    default:
      return state;
  }
}
