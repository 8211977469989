import React, { Component } from "react";
import {
  SENDER_NAME,
  SENDER_PHONE,
  SERVICETYPE,
  _TS,
  STATUS,
  CAR_BRAND,
  _FEE
} from "../../Helpers/Constants";
import { formatDate, statusString } from "../../Helpers/Helper";

class RequestDetails extends Component {
  render() {
    const { request } = this.props;
    return (
      <div>
        <div className="card bg-primary">
          <div className="card-body bg-light"></div>
        </div>
      </div>
    );
  }
}

export default RequestDetails;

/**
 * <div className="row">
              <ul
                className="list-group list-group-flush float-left"
                style={{ border: "none", backgroundColor: "#fff" }}
              >
                <li className="list-group-item bg-dark text-left">
                  Client Name: {request[SENDER_NAME]}
                </li>
                <li className="list-group-item bg-light text-left">
                  Client Phone: {request[SENDER_PHONE]}
                </li>
                <li className="list-group-item bg-dark text-left">
                  Service: {request[SERVICETYPE]}
                </li>
                <li className="list-group-item bg-dark text-left">
                  Car Brand: {request[CAR_BRAND]}
                </li>
                <li className="list-group-item bg-dark text-left">
                  Estimate Fee: {`N ${request[_FEE]}`}
                </li>
                <li className="list-group-item bg-dark text-left">
                  Date: {formatDate(request[_TS])}
                </li>
                <li className="list-group-item bg-dark text-left">
                  Request Status: {statusString(request[STATUS])}
                </li>
              </ul>
            </div>
 */
