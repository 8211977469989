import * as moment from "moment";

export const statusString = status => {
  if (status === 1) {
    return "PENDING";
  } else if (status === 2) {
    return "IN-SERVICE";
  } else if (status === 3) {
    return "SERVICED";
    //statusChanger.setBackgroundColor(ContextCompat.getColor(context,R.color.evedark));
  }
};

export function formatDate(date) {
  var md = moment(date).format("MM/DD/YY, h:mm:ss a");
  return md;
}
