import React, { Component } from "react";
import appicon from "../../img/aicon44.png";
import TextFieldGroup from "../layout/TextinputGroup";

import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
//import { loginwith } from "../../Helpers/authentication";
import { loginAdmin } from "../../actions/authAction";
import PropTypes from "prop-types";

class Login extends Component {
  constructor() {
    super();

    this.state = {
      email: "",
      password: "",
      errors: {}
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.auth.isAuthenticated) {
      this.props.history.push("/dashboard");
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();
    const userData = {
      email: this.state.email,
      password: this.state.password
    };
    //console.log(userData);
    const { loginAdmin } = this.props;
    loginAdmin(userData);

    // loginwith(userData.email, userData.password)
    //   .then(user => {
    //     //Call Dispatch to set state
    //     console.log(user);
    //   })
    //   .catch(err => {
    //     console.log(err.errors);
    //   });

    // firebase
    //   .login(userData)
    //   .catch(err =>
    //     this.setState({ errors: { email: "Invalid user credentials" } })
    //   );
    //this.props.loginUser(userData);
  };

  render() {
    const { errors } = this.state;

    return (
      <div className="login mt-0">
        <div className="container">
          <div className="row-fluid">
            <div className="col-md-8 col-xl-6 col-sm-10 m-auto">
              <h3 className="display-5 text-center text-white">
                iAutomech Admin Dashboard
              </h3>
              <div className="card mt-5 shadow-lg p-3 mb-5 bg-white rounded">
                <div className="card-body">
                  <form className="form-signin" onSubmit={this.onSubmit}>
                    <div className="text-center mb-4">
                      <img
                        className="mb-4"
                        src={appicon}
                        alt=""
                        width="72"
                        height="72"
                      />
                    </div>
                    <TextFieldGroup
                      placeholder="Email Addresss"
                      name="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.onChange}
                      error={errors.email}
                    />
                    <TextFieldGroup
                      placeholder="Password"
                      name="password"
                      type="password"
                      value={this.state.password}
                      onChange={this.onChange}
                      error={errors.password}
                    />
                    <button
                      style={{ backgroundColor: "#cb2027", border: "none" }}
                      className="btn btn-lg btn-primary btn-block"
                      type="submit"
                    >
                      Sign in
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Login.propTypes = {
//   loginwith: PropTypes.func.isRequired
// };

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(
  mapStateToProps,
  { loginAdmin }
)(withRouter(Login));
