import { combineReducers } from "redux";

import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import requestReducer from "./requestReducers";
import analyticsReducer from "./analyticsReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  requests: requestReducer,
  analytics: analyticsReducer
});
