import React, { Component } from "react";
import { connect } from "react-redux";
import ServiceProviderDetails from "../Dashboard/ServiceProviderDetail";
class Sidebar extends Component {
  constructor() {
    super();
    this.state = {
      auth: null
    };
  }
  render() {
    const auth = this.state;
    if (auth) {
      return (
        <div className="side-detail">
          <ServiceProviderDetails></ServiceProviderDetails>
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => ({
  auth: state.auth
});

export default connect(mapStateToProps)(Sidebar);
