import React, { Component } from "react";

class AppFooter extends Component {
  render() {
    return (
      <div className="footer">
        <footer className="footer_part">
          <hr />
          <div className="row">
            <div className="col-lg-8">
              <div className="copyright_text">
                <p className="text-light">
                  Copyrights © IAUTOMECH {new Date().getFullYear()}. || Address:
                  7 Myca Court, Orchid Road, Ikota, Lekki, Lagos.
                </p>
                <p className="text-light">
                  {" "}
                  Phone:08181643334, 07012539490, 08103757100, 07069172440. ||
                  Email: iautoservicess@gmail.com
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="footer_icon social_icon">
                <ul className="list-unstyled">
                  <li>
                    <a href="#" className="single_social_icon">
                      <i className="fab fa-facebook-f" />
                    </a>
                  </li>
                  <li>
                    <a href="#" className="single_social_icon">
                      <i className="fab fa-twitter" />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
      </div>
    );
  }
}

export default AppFooter;
