import React, { Component } from "react";
import RequestTable from "./RequestTable";
import { requestTableData, analyticData } from "../../MockData/AnalyticTests";
import Spinner from "../layout/Spinner";
import ServiceProviderDetails from "../Dashboard/ServiceProviderDetail";
import { AnalyticData } from "../../Models/AnalyticData";
import { connect } from "react-redux";
import {
  getAnalyticData,
  getSuperAnalytics
} from "../../actions/analyticsactions";

class Analytics extends Component {
  constructor() {
    super();

    this.state = {
      tableData: null,
      summary: null
    };
  }

  componentDidMount() {
    const { getAnalyticData, auth } = this.props;
    console.log(auth);

    const { uid, isSuper } = auth.user;
    //this.setState({ tableData: requestTableData, analyticData });
    if (isSuper) {
      this.props.getSuperAnalytics();
    } else {
      getAnalyticData(uid);
    }
  }

  componentWillReceiveProps(nextProps) {
    console.log(nextProps);

    if (nextProps.analytics) {
      const { analytics } = nextProps;
      console.log(analytics.data);

      const data = new AnalyticData(analytics.data);
      this.setState({
        tableData: data.requestStats(),
        summary: data.summaryData()
      });
    } else {
      const data = new AnalyticData(null);
      this.setState({
        tableData: data.requestStats(),
        summary: data.summaryData()
      });
    }
  }

  render() {
    const { tableData, summary } = this.state;

    if (tableData === null || summary == null) {
      return <Spinner />;
    }

    return (
      <div>
        <div className="side-detail">
          <ServiceProviderDetails></ServiceProviderDetails>
        </div>
        <div className="main-dashboard-container">
          <section>
            <div className="container-fluid">
              <div className="row">
                <div className="col-4 mt-4">
                  <h3 className="display-4 text-left text-muted">Analytics</h3>
                </div>
              </div>
            </div>
          </section>
          <section>
            <div class="container-fluid">
              <div class="row ">
                <div class="col">
                  <div class="row pt-md-2 mt-md-3 mb-5">
                    <div class="col-sm-6 p-2 col-xl-3 ">
                      <div class="card card-common">
                        <div class="card-body">
                          <div class="d-flex justify-content-between">
                            <i class="fas fa-shopping-cart fa-3x text-warning" />
                            <div class="text-right text-secondary">
                              <h5>Sales</h5>
                              <h3>{summary.sales}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-secondary">
                          <i class="fas fa-sync mr-3">
                            <span class="ml-3"> Updated Now</span>
                          </i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 p-2 col-xl-3 ">
                      <div class="card card-common ">
                        <div class="card-body">
                          <div class="d-flex justify-content-between">
                            <i class="fas fa-money-bill-alt fa-3x text-success" />
                            <div class="text-right text-secondary">
                              <h5>Requests</h5>
                              <h3>{summary.requests}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-secondary">
                          <i class="fas fa-sync mr-3">
                            <span class="ml-3"> Updated Now</span>
                          </i>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6 p-2 col-xl-3 ">
                      <div class="card card-common">
                        <div class="card-body">
                          <div class="d-flex justify-content-between">
                            <i class="fas fa-chart-line fa-3x text-warning" />
                            <div class="text-right text-info">
                              <h5>Completed</h5>
                              <h3>{summary.completed}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-secondary">
                          <i class="fas fa-sync mr-3">
                            <span class="ml-3"> Updated Now</span>
                          </i>
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 p-2 col-xl-3 ">
                      <div class="card card-common">
                        <div class="card-body">
                          <div class="d-flex justify-content-between">
                            <i class="fas fa-users fa-3x text-primary" />
                            <div class="text-right text-info">
                              <h5>Clients</h5>
                              <h3>{summary.clients}</h3>
                            </div>
                          </div>
                        </div>
                        <div class="card-footer text-secondary">
                          <i class="fas fa-sync mr-3">
                            <span class="ml-3"> Updated Now</span>
                          </i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <RequestTable data={tableData} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.auth,
  analytics: state.analytics
});

export default connect(
  mapStateToProps,
  { getAnalyticData, getSuperAnalytics }
)(Analytics);
