import React, { Component } from "react";
import "./App.css";
import "./css/atlantis.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Login from "./components/Auth/Login";
import Dashboard from "./components/Dashboard/Dashboard";
import AppNavBar from "./components/layout/AppNavBar";
import About from "./components/About/About";
import Footer from "./components/layout/AppFooter";
import Analytics from "./components/Analytics/Analytics";

import PrivateRoute from "./components/Security/PrivateRoute";
import Sidebar from "./components/layout/Sidebar";

console.log(store);
class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <AppNavBar />
            <Route exact path="/login" component={Login} />
            <Route exact path="/" component={About} />
            <Switch>
              <PrivateRoute exact path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/analytics" component={Analytics} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}

// const LoginContainerLayout = () => {
//   return <Login />;
// };

// const AuthenticatedContainerLayout = () => {
//   return (
//     <div>
//       <Route exact path="/" component={Dashboard} />
//     </div>
//   );
// };

export default App;
