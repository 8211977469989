import React, { Component } from "react";
import homescren from "../../img/homescreen.png";
import playbadge from "../../img/googleplaybadge.png";
import AppFooter from "../layout/AppFooter";

class About extends Component {
  render() {
    return (
      <div className="container-main">
        <div className="about-container">
          <div className="containaer-item">
            <img className="homescreen" src={homescren} alt="HomeScreen.png" />
          </div>
          <div className="container-item">
            <h3 className="text-light">iAutomech</h3>
            <p className="text-light service-about">
              IAUTOMECH is a tech-enabled company that manages an application
              which provides automobile diagnostics and links up car users to
              auto-mechanics
            </p>
            <img
              className="playbadge"
              src={playbadge}
              alt="Google Play Badge"
            />
          </div>
        </div>
        <section className="feature_part padding_top">
          <div className="container">
            <div className="row align-items-center justify-content-between">
              <div className="col-lg-6 ">
                <div className="row align-items-center">
                  <div className="col-lg-6 col-sm-6">
                    <div className="single_feature">
                      <div className="single_feature_part">
                        <img src="img/icon/feature_icon_1.png" alt="" />
                        <h4>Our Services</h4>
                        <ul>
                          <li>
                            Easily understandable car diagnostics for car user
                          </li>
                          <li>Quick link up to automobile service providers</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-sm-6">
                    <div className="single_feature">
                      <div className="single_feature_part">
                        <img src="img/icon/feature_icon_2.png" alt="" />
                        <h4>Our Product</h4>
                        <ul>
                          <li>ELM device</li>
                        </ul>
                      </div>
                    </div>
                    <div className="single_feature">
                      <div className="single_feature_part single_feature_part_2">
                        <img src="img/icon/feature_icon_3.png" alt="" />
                        <h4>OUR FOUNDING</h4>
                        <p>
                          IAUTOMECH was founded in 2016 by Omotolani Lukman
                          Ajetunmobi, he was later joined by Chisom Pearl Ezenwa
                          and the following year by Chibueze Ignatius Nwafor and
                          Reuben Ifeanyi Ovili. His passion was derived from the
                          unbearable feeling of seeing car users constantly
                          exploited by automobile service providers and this
                          drove him into automobile research and further into
                          auto-mechanic trainings. His diligence coupled with
                          his willingness to find a solution was what brought
                          him to the idea that made IAUTOMECH.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="feature_part_text single_feature_part">
                  <h2>About Us</h2>
                  <p>
                    At IAUTOMECH, we believe that what carries you, is as
                    important as where you are going to and that is why we are
                    dedicated to three things: providing quick, quality and
                    affordable automobile services for car users. we stand for
                    everyone in the automobile industry; the car users, the
                    manufacturers and the fixers. We work to keep the
                    manufacturer’s product at the best possible condition, task
                    ourselves to get the fixers as busy as possible by providing
                    them with continuous jobs and we strive to give the car
                    users a beautiful driving experience. To us, passion is
                    everything; when one loves, they go on to express it and in
                    IAUTOMECH, we express our love for the industry through our
                    services. Every car user drives with the hope of a
                    worry-free ride and we see it as our duty to make that
                    possible. When one is assured of more income, they are not
                    afraid to spend and it is our believe that same applies to
                    vehicle usage; when you are sure to access quality and
                    affordable automobile services as fast as possible, you
                    drive with no worries and that is why...you drive and we
                    fix.
                  </p>
                  <div className="row">
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <div className="feature_part_text_iner">
                        <h6>Vision</h6>
                        <p>
                          To become the world’s number one auto-mechanic
                          tech-enables corporation.
                        </p>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4 col-lg-5">
                      <div className="feature_part_text_iner">
                        <h6>Mission</h6>
                        <p>
                          Make quality automobile services affordable and easily
                          assessable to car users.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <AppFooter></AppFooter>
      </div>
    );
  }
}

export default About;
