import React, { Component } from "react";

export default class RequestTable extends Component {
  constructor() {
    super();
  }
  render() {
    const { data } = this.props;
    return (
      <div>
        <section>
          <div class="container-fluid">
            <div class="row mb-5">
              <div class="col-xl-10 col-lg-9 colmd-8 ml-3">
                <div class="row">
                  <div class="col-12 mb-4 ">
                    <h3 class="text-muted text-center mb-3">Requests</h3>
                    <table class="table bg-light table-striped right-text-center table-bordered card-common">
                      <thead>
                        <tr class="text-muted">
                          <th>#</th>
                          <th>Service</th>
                          <th>Requests</th>
                          <th>Revenue</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map(element => {
                          return (
                            <tr key={element.key}>
                              <th>{data.indexOf(element)}</th>
                              <td>{element.key}</td>
                              <td>{element.requests} Requests</td>
                              <td>{element.revenue}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}
