export const user = {
  name: "Harold & Sona Repair Shop",
  serviceType: ["AUTOMECHANIC", "AUTOELECTRICIAN", "AUTOBODY MECH", "TOW"],
  supportedVehicles: [
    "TOYOTA/LEXUS",

    "NISSAN",
    "HONDA/ACURA",
    "MERCEDES BENZ/AMG",
    "BMW",
    "HYUNDAI/KIA",
    "FORD",
    "LAND/RANGE ROVER",
    "VOLKSWAGEN",
    "PORSCE",
    "CHEVROLETE",
    "GMC",
    "VOLVO",
    "PEUGOT/RENAULT"
  ],
  primaryPhone: "+0200016778",
  secondaryPhone: "+23-00-00038",
  workshopLocation: "Nii Amasa Oseiku II Ave Amasaman ",
  currentState: "AKWABIOM",
  onSpotRepairs: true
};
