import React, { Component } from "react";
import { connect } from "react-redux";
import { getRequestFor, requestsForSuper } from "../../actions/requestActions";
import SummaryTable from "./SummaryTable";
import ServiceProviderDetails from "./ServiceProviderDetail";
import Paginate from "./Paginate";

class Dashboard extends Component {
  constructor() {
    super();
    this.state = {
      requests: null,
      last: null,
      previous: [],
      next: []
    };
  }

  componentDidMount() {
    const { uid, isSuper } = this.props.auth.user;
    if (uid) {
      if (isSuper) {
        this.props.requestsForSuper();
      }
      this.props.getRequestFor(uid);
    }
  }

  loadPrevious() {
    const { previous, next, requests } = this.state;
    if (previous.length > 0) {
      next.push(requests);
      const nrequests = previous.pop();
      this.setState({ requests: nrequests, previous, next });
    }
  }

  loadNext() {
    const { uid, isSuper } = this.props.auth.user;
    const { last, next, requests, previous } = this.state;
    if (requests) {
      previous.push(requests);
    }
    if (next.length > 0) {
      const requests = next.pop();
      this.setState({ requests, next, previous });
      return;
    } else {
      this.setState({ previous });
    }
    if (last) {
      if (isSuper) {
        this.props.requestsForSuper(last);
      } else {
        this.props.getRequestFor(uid, last);
      }
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.requests.requests) {
      console.log(nextProps.requests);
      const { requests } = nextProps.requests;
      const { docs, last } = requests;
      console.log(docs);
      this.setState({ requests: docs, last });
    }
  }

  render() {
    const { requests } = this.state;
    console.log("I should be logged");
    console.log(requests);

    return (
      <div className="bg-gtb full-page">
        <div className="">
          <div className="side-detail">
            <ServiceProviderDetails></ServiceProviderDetails>
          </div>
          <div className="main-dashboard-container">
            <div className="row">
              <div className="col-md-6 pt-5">
                <h6 className="display-4 text-left text-muted">Summary</h6>
              </div>
            </div>
            <div className="row">
              {requests ? (
                <SummaryTable requests={requests} />
              ) : (
                <h6 className="display-3 text-danger">Loading....</h6>
              )}
            </div>
            <div className="row justify-content-center">
              <Paginate
                loadPrevious={this.loadPrevious.bind(this)}
                loadNext={this.loadNext.bind(this)}
              ></Paginate>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  requests: state.requests,
  auth: state.auth
});

export default connect(
  mapStateToProps,
  { getRequestFor, requestsForSuper }
)(Dashboard);

/***
 * 
 *           <div className="side-detail">
            <h4 className="text-dark">Details here</h4>
          </div>
 */
