import * as firebase from "firebase";
import {
  REF_SERVICE_PROVIDERS,
  REF_ANALYTICS,
  GET_ANALYTICS,
  GET_ERRORS
} from "../Helpers/Constants";

export const getAnalyticData = uid => dispatch => {
  firebase
    .firestore()
    .doc(`${REF_ANALYTICS}/${uid}/`)
    .get()
    .then(data => {
      console.log("Analytica here");
      console.log(data);

      dispatch({
        type: GET_ANALYTICS,
        payload: data.data()
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: { msg: "error occurred fetching analytics" }
      });
    });
};

export const getSuperAnalytics = () => dispatch => {
  firebase
    .firestore()
    .doc(`${REF_ANALYTICS}/Master`)
    .get()
    .then(data => {
      console.log("Analytica here");
      console.log(data);

      dispatch({
        type: GET_ANALYTICS,
        payload: data.data()
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: { msg: "error occurred fetching analytics" }
      });
    });
};
