const APP_VERSION = 5;
const _VERSION = "version";
const UPDATABLE = "updatable";
const APP_LINK = "link";
const USER_UID = "uid";
const _ID = "id";
const USERNAME = "name";
const EMAIL = "email";
export const LINK = "link";
export const PROVIDER_NAME = "spname";
export const CONTACTNAME = "contactName";
export const ADDRESS_WORK = "worskshopAddress";
export const ADDRESS_HOME = "homeAddress";
export const CURRENT_STATE = "currentState";
export const LOCATION_WORK = "workshopLocation";
export const PHONE_PRIMARY = "primaryPhone";
export const PHONE_SECONDARY = "secondaryphone";
export const ON_SPOT_REP = "onSpotRepairs";
export const REF_SUPPORTED = "supportedVehicles";
export const _LONGITUDE = "longitude";
export const _LATITUDE = "latitude";
export const SERVICETYPE = "serviceType";
export const DB_REF_TOKENS = "IATOKENS";
export const TOKEN = "token";
export const REF_USERS = "Users";
export const REF_SERVICE_PROVIDERS = "ServiceProviders";
export const REF_ANALYTICS = "IAANALYTICS";
export const CAR_BRAND = "carBrand";
export const SENDER_ID = "senderID";
export const PROVIDER_ID = "providerID";
export const REQUESTS = "Requests";
export const SERVICE_TYPE = "servicetype";
export const STATUS = "status";
export const _TS = "timestamp";
export const _FEE = "fee";
export const REF_IAREQUESTS = "IARequests";
export const REF_IAAUTOMECHREQUESTS = "IAServiceAutoRequests";
export const REF_IATOWREQUESTS = "IAServiceTowRequests";
export const JSON_KEY_DURATION = "duration";
export const JSON_KEY_DISTANCE = "distance";
export const JSON_KEY_TEXT = "text";
export const JSON_KEY_VALUE = "value";
export const DIST_VAL = "disval";
export const DUR_VAL = "durval";
export const _TYPEOF_PROVIDER = 100;
export const _TYPE_USER = 200;
export const USER_TYPE = "usertype";
export const SENDER_NAME = "senderName";
export const _HIST = 20;
export const SP_HIST = 30;
export const SENDER_PHONE = "senderPhone";
export const PROVIDER_PHONE = "providerPhone";
export const IACREDITACCOUNTS = "IACreditAccount";
export const TOW_DIST = "towDistance";

//TYPES

export const AUTH_DISPATCH = "AUTH_DISPATCH";
export const LOGOUT_DISPATCH = "LOGOUT_DISPATCH";
export const GET_ERRORS = "GET_ERRORS";
export const GET_REQUEST = "GET_REQUEST";
export const GET_ANALYTICS = "GET_ANALYTICS";
